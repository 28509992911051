<template>
  <v-card
    min-height="100vh"
    outlined
    flat
    class="rounded-10 overflow-hidden support-home"
  >
    <v-card-title style="min-height: 250px" class="primary d-block rounded-0">
      <v-row dense align="center" justify="space-between">
        <v-breadcrumbs light :items="paths">
          <template v-slot:divider>
            <v-icon color="white">mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              @click="$router.push({ path: item.href })"
              :disabled="item.disabled"
            >
              <span class="white--text cursor-pointer">{{ item.text }}</span>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <div>
          <v-btn
            v-if="canManageCourse"
            @click="createPost"
            class="text-none ma-1"
            outlined
            color="white"
            small
          >
            Create Post <v-icon small right>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="canManageCourse"
            @click="manageCategories"
            class="text-none ma-1"
            outlined
            color="white"
            small
          >
            Manage Categories <v-icon small right>mdi-cog</v-icon>
          </v-btn>
        </div>
      </v-row>
      <v-row dense align="center" class="my-5" justify="center">
        <v-col
          cols="12"
          md="8"
          sm="10"
          class="white--text text-center fw-900 fs-20"
        >
          Learn some tips and guides for your business and self <br />from
          {{ settings.SiteName }} itself
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-sheet
        outlined
        color="white"
        class="d-flex rounded-5 align-center mian-contennt justify-start flex-column mx-auto py-md-5"
        max-width="95%"
      >
        <strong class="primary--text fs-25 fw-700">
          Browse for training courses.
        </strong>
        <v-row
          dense
          class="flex-wrap my-4 mx-md-5 mx-sm-2"
          align="start"
          justify="start"
        >
          <v-col
            v-for="category in categories"
            :key="category.id"
            cols="12"
            md="12"
            sm="12"
          >
            <v-hover v-slot:default="{ hover }">
              <v-sheet
                :elevation="hover ? 3 : 0"
                min-height="200"
                class="rounded-5 ma-2 pa-5 d-flex align-center justify-start"
                outlined
              >
                <div class="d-flex col-md-3 pa-3 align-center justify-center">
                  <v-icon color="primary" size="100">mdi-cast-education</v-icon>
                </div>
                <div class="d-flex align-stretch justify-center flex-column">
                  <strong
                    @click="viewCategory(category)"
                    class="cursor-pointer primary--text mb-2 fs-20"
                  >
                    {{ category.title | ucwords }}
                  </strong>
                  <div>
                    <v-btn
                      v-for="sub in category.subcategories"
                      :key="sub.id"
                      color="secondary"
                      outlined
                      link
                      text
                      small
                      @click="viewSubcategories(category, sub)"
                      class="ma-1 text-none"
                    >
                      {{ sub.title | ucwords }}
                    </v-btn>
                  </div>
                </div>
              </v-sheet>
            </v-hover>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data: () => ({
    loading: false,
    categories: [],
    paths: [
      {
        text: 'Dashboard',
        disabled: true,
        href: '/dashboard'
      },
      {
        text: 'Training',
        disabled: true,
        href: '/dashboard/courses'
      }
    ]
  }),
  created() {
    this.fetchCategories()
  },
  computed: {
    ...mapGetters(['user']),
    canManageCourse() {
      return this.user.is_super_admin
    }
  },
  methods: {
    fetchCategories() {
      this.loading = true
      request
        .get(`api/courses/categories`)
        .then(({ data }) => {
          this.categories = data
        })
        .finally(() => (this.loading = false))
    },
    viewCategory(category) {
      this.$router.push({
        name: 'courses-category',
        params: {
          slug: category.slug
        }
      })
    },
    viewSubcategories(main, sub) {
      this.$router.push({
        name: 'courses-subcategory',
        params: {
          mainslug: main.slug,
          subslug: sub.slug
        }
      })
    },
    createPost() {
      this.$router.push({
        name: 'create-update-series'
      })
    },
    manageCategories() {
      this.$router.push({
        name: 'manage-course-categories'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.mian-contennt {
  margin-top: -50px;
  min-height: 100vh;
}
</style>
